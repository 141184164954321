<template>
  <div class="w-100 section flex-container">
    <div class="my-auto w-100">
      <h1 id="title">Accelerate your career with <span class="logo-text dark-color">rfrl</span></h1>
      <p>Get referral to your dream job and excell in life.</p>
      <button id="main-button" class="primary-btn primary-btn-dark" type="submit">
         <a class="no-style-link" :href="SignUpURL">Start free trial</a>
      </button>
    </div>
  </div>
</template>

<script>
import {SIGN_UP_URL} from '@/conf';

export default {
  data() {
    return {
      SignUpURL: SIGN_UP_URL,
    };
  },
};
</script>

<style scoped>
.dark-color{
  color: var(--clr-accent);
}

.section{
  text-align: center;
  height: 25rem;
}

#title {
  color: var(--clr-accent);
  font-size: 2.5rem;
  font-weight: 400;
}

#main-button {
  padding-top:0.75rem;
  padding-bottom: 0.75rem;
  box-shadow:0 5px 15px 0 rgba(0,0,0,0.15);
}
</style>
